import React, {useEffect} from 'react';
import {fontObserver} from '../helper/font';
import sanity, {imageUrl} from '../lib/sanity';
import Page from '../components/page';
import Header from '../components/header';
import {ContentSection} from '../components/section';
import Content from '../components/content';
import Slider from '../components/slider';
import {H2} from '../components/webComponents';
import Footer from '../components/footer';
import SeoHeader from '../components/seoHeader';

const pageQuery = `*[_type == "page" && slug.current=="index"] {
  _id,
  title,
  slug,
  description,
  image,
  content,
  tabs
}[0]`;

const productCategoriesQuery = `*[_type == "productCategory"] {
  _id,
  title,
  slug,
  image,
  "products": *[_type == "product" && _id in ^.products[]._ref]
}`;

const Home = ({page, productCategories}) => {
  useEffect(() => {
    fontObserver();
  }, []);
  return (
    <Page>
      <SeoHeader title={page?.title} description={page?.description} image={imageUrl(page?.image, 80, 600)} url={'/'} />
      <Header
        title={page?.title}
        description={page?.description}
        placeholderImage={imageUrl(page?.image, 10, 10)}
        largeImage={imageUrl(page?.image, 80, 1000)}
      />
      <ContentSection>{page?.content && <Content content={page?.content} />}</ContentSection>
      {productCategories?.length > 0 && (
        <ContentSection>
          <H2>Produkt-Kategorien</H2>
          <Slider
            grid
            items={productCategories?.map((item) => ({
              line1: item?.title,
              line2: `${item?.products?.length} ${item?.products?.length === 1 ? `Produkt` : `Produkte`}`,
              image: imageUrl(item?.image, 80, 500),
              as: `/produkt-kategorie/${item?.slug?.current}`,
              link: `/produkt-kategorie/[slug]`,
            }))}
          />
        </ContentSection>
      )}
      <Footer />
    </Page>
  );
};

export const getStaticProps = async () => {
  const page = await sanity.fetch(pageQuery);
  const productCategories = await sanity.fetch(productCategoriesQuery);
  return {
    props: {page, productCategories},
    revalidate: 10,
  };
};

export default Home;
